module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-181927391-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Los Agaves Grill","short_name":"GatsbyJS","start_url":"/","background_color":"#ffffff","theme_color":"#3C2416","display":"minimal-ui","icon":"content/assets/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0b4577976e210f94df95879f6cfdcd4b"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"agaves-website","defaultLang":"en-us","accessToken":"MC5YdEt3YnhFQUFBclFOSk03.RwEW77-977-977-9au-_vUZ477-977-977-9dC_vv70FSu-_ve-_vR4CQu-_vVrvv71X77-977-9IXAq","path":"/preview","previews":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
