// custom typefaces
import "typeface-bree-serif"
import "typeface-raleway"

import "./src/css/style.css"

// import { registerLinkResolver } from "gatsby-source-prismic-graphql"
// import { linkResolver } from "./src/utils/linkResolver"

// registerLinkResolver(linkResolver)
